import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { User } from '@app/_models/user';

@Injectable({ providedIn: 'root' })
export class UserService {
    paymentMethod;
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    public setPaymentMethod(method){
        this.paymentMethod = method;
    }

    public getPaymentMethod(){
        return this.paymentMethod;
    }
}